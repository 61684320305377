<template>
    <div class="Cognitivecomb">
        <div class="countdown">
            <div class="clearfix" style="width:100%">
                <div class="div_titles fl">认知梳理</div>
                <div class="count_span fl" v-show="countNum==1">
                    <span>{{count.name}}倒计时：</span>
                    <span class="timer_s">{{timer}}</span>
                </div>
                <button class="count_but fr" @click="submit()" v-show="searchVal<8">提交</button>
            </div>
        </div>
        <div class="Cogn_content">
            <div class="Cogn_content_div">
                <div class="cogn_div">
                    <div class="cogn_div_one">
                        <div class="Goodat">
                            <i class="iconfonts icons-shanchanghangye"></i>
                            <span>我很擅长</span>
                        </div>
                        <!-- 弹框 -->
                        <div class="cogn_popup">
                            <ul class="cogn_ul cogn_one">
                                <li class="cogn_ul_li clearfix" v-for="(item,index) in forms.p1.values" :key="index">
                                    <span class="cogn_ul_li_span fl">
                                        {{item.name}}
                                    </span>
                                    <ul class="cogn_ul_item fl">
                                        <li class="cogn_ul_li_item fl" 
                                            v-for="(items,indexs) in item.values" :key="indexs" 
                                            :class="items.val==1?'active':''"
                                            @click="itemsnum('p1',index,indexs,items)"
                                        >
                                            <span>{{items.name}}</span>
                                            <i v-show="item.type=='zdy'" class="el-icon-close close_i" :class="items.val==0?'one_close':'one_closes'" @click.stop="delclose('p1',index,indexs,items)"></i>
                                        </li>
                                        <li class="cogn_ul_li_item fl" v-show="item.type=='zdy'" @click="addBotn('p1')">
                                            <i class="el-icon-plus"></i>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="cogn_div_two">
                        <div class="Goodat">
                            <i class="iconfonts icons-xihuan2"></i>
                            <span>我很喜欢</span>
                        </div>
                        <!-- 弹框 -->
                        <div class="cogn_popup">
                            <ul class="cogn_ul cogn_two">
                                <li class="cogn_ul_li clearfix" v-for="(item1,index1) in forms.p2.values" :key="index1">
                                    <span class="cogn_ul_li_span fl">
                                        {{item1.name}}
                                    </span>
                                    <ul class="cogn_ul_item fl">
                                        <li class="cogn_ul_li_item fl" 
                                            v-for="(items,indexs) in item1.values" :key="indexs" 
                                            :class="items.val==1?'active':''"
                                            @click="itemsnum('p2',index1,indexs,items)"
                                        >
                                            <span>{{items.name}}</span>
                                            <i v-show="item1.type=='zdy'" class="el-icon-close close_i" :class="items.val==0?'two_close':'two_closes'" @click.stop="delclose('p2',index1,indexs,items)"></i>
                                        </li>
                                        <li class="cogn_ul_li_item fl" v-show="item1.type=='zdy'" @click="addBotn('p2')">
                                            <i class="el-icon-plus"></i>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="cogn_divs">
                    <div class="cogn_div_three">
                        <div class="Goodat">
                            <i class="iconfonts icons-yewujihui"></i>
                            <span>潜在机会</span>
                        </div>
                        <div class="cogn_three">
                            <ul class="cogn_three_ul"  v-show="sortList.length!=0">
                                
                                <li class="cogn_three_ul_li" v-for="(item3,index3) in sortList" :key="index3">
                                    <div class="cogn_three_div show_t_div clearfix">   
                                        <span class="fl">热度为{{item3.level}}颗星的：</span>
                                        <span class="fl">
                                            <el-rate
                                                v-model="item3.level"
                                                disabled
                                                :colors="['#ff9900','#ff9900','#ff9900','#ff9900','#ff9900']"
                                                 >
                                            </el-rate>
                                        </span>
                                       
                                        <!-- <i class="el-icon-star-on" v-for="(citem,cindex) in item3.level" :key="cindex"></i> -->
                                    </div>
                                    <ul class="icon_ul clearfix">
                                        <li class="cogn_ul_li_item fl" v-for="(items3,indesx3) in item3.values" :key="indesx3">
                                            {{items3.name}}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="cogn_div_four">
                        <div class="Goodat">
                            <i class="iconfonts icons-tongjiyuyunyong"></i>
                            <span>曾经应用</span>
                        </div>
                        <div class="cogn_popup">
                            <ul class="cogn_ul cogn_four">
                                <li class="cogn_ul_li clearfix" v-for="(item4,index4) in forms.p4.values" :key="index4">
                                    <span class="cogn_ul_li_span fl">
                                        {{item4.name}}
                                    </span>
                                    <ul class="cogn_ul_item fl">
                                        <li class="cogn_ul_li_item fl" 
                                            v-for="(itemss,indexss) in item4.values" :key="indexss" 
                                            :class="itemss.val==1?'active':''"
                                            @click="itemsnum('p4',index4,indexss,itemss)"
                                        >
                                            <span>{{itemss.name}}</span>
                                            <i v-show="item4.type=='zdy'" class="el-icon-close close_i" :class="itemss.val==0?'four_close':'four_closes'" @click.stop="delclose('p4',index4,indexss,itemss)"></i>
                                        </li>
                                        <li class="cogn_ul_li_item fl" v-show="item4.type=='zdy'" @click="addBotn('p4')">
                                            <i class="el-icon-plus"></i>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 弹窗 -->
        <div class="cogn_ul_popup">
            <el-dialog
                :title="titleName"
                :visible.sync="dialogVisible"
                width="500px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei('ruleForm')"></i>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" size="small" @submit.native.prevent>
                    <el-form-item label="分类名称" prop="name">
                        <el-input v-model="ruleForm.name" placeholder="请输入分类名称" maxlength="10" show-word-limit @keyup.enter.native="enterInput($event)"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="closei('ruleForm')">取消</el-button>
                    <el-button type="primary" size="mini" @click="subxit('ruleForm')">确定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    props: ['searchVals'],
    data() {
        var checkScore = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("分类名称不能为空"));
            }else{
                this.axios.s1filter({
                    token:this.$store.state.token,
                    id:this.$store.state.cla_id,
                    node:this.ruleForm.node,
                    value:value
                }).then(res=>{
                    if(res.data.code==0){
                        callback();
                    }else{
                        callback(new Error(res.data.message));
                    }
                }).catch(err=>{

                })
            }
        }
        return {
            dialogVisible:false,
            titleName:'',
            ruleForm:{
                name:''
            },
            rules:{
                name:[
                    { validator: checkScore,trigger:'blur'}
                ]
            },
            count:{},
            form:{},
            forms:{
                p1:{},
                p2:{},
                p3:{},
                p4:{}
            },
            input2:'',
            arrList:[],
            sortList:[],
            countNum:0,
            timer:'',
            searchVal:0
        }
    },
    methods: {
        getapp(){
            this.searchVal = this.searchVals
            this.axios.StuCprojectgets1({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data
                    this.forms = res.data.data.data
                    this.sortList = this.forms.p3.values
                    this.sortList.sort((a, b) => {
                        //排序基于的数据
                        return b.level - a.level; //降序
                    });
                    var str = this.forms.resource
                    this.$emit('info', str);
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        // 获取倒计时
        down(){
            this.axios.StudctdGet({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    if(res.data.data!='' && res.data.data!=null && res.data.data!='null'){
                        this.countNum = 1
                        this.count = res.data.data
                        this.time = res.data.data.times
                        if(this.count.state==1){
                            this.countDown()
                        }else{
                            let h = parseInt(this.time / (60 * 60) % 24);
                            h = h < 10 ? "0" + h : h
                            let m = parseInt(this.time / 60 % 60);
                            m = m < 10 ? "0" + m : m
                            let s = parseInt(this.time % 60);
                            s = s < 10 ? "0" + s : s
                            this.timer =  m +':'+ s
                            this.timeoutss = setTimeout(this.down,5000)
                        }
                    }else{
                        this.timeout = setTimeout(this.down,3000)
                        this.countNum = 2
                    }
                }else if(res.data.code==-4054){
                    this.timeout = setTimeout(this.down,3000)
                    this.countNum = 2
                    // this.$message.error(res.data.message)
                }else{
                  this.countNum = 2
                }
            }).catch(err=>{

            })
        },
        //倒计时
        countDown(){
            if(this.time>0){
                let h = parseInt(this.time / (60 * 60) % 24);
                h = h < 10 ? "0" + h : h
                let m = parseInt(this.time / 60 % 60);
                m = m < 10 ? "0" + m : m
                let s = parseInt(this.time % 60);
                s = s < 10 ? "0" + s : s
                this.timer =  m +':'+ s
                this.time--
                this.timeouts = setTimeout(this.countDown,1000)
            }else{
                this.timer = '00' +':'+ '00' +':'+ '00'
            }
        },
        addBotn(pn){
            if(pn==="p1"){
                this.titleName='我很擅长自定义分类'
                this.arrList = this.forms.p1.values
                this.ruleForm.node = pn
                this.dialogVisible = true
            }else if(pn==="p2"){
                this.titleName='我很喜欢自定义分类'
                this.arrList = this.forms.p2.values
                this.ruleForm.node = pn
                this.dialogVisible = true
            }else if(pn==="p4"){
                this.titleName='曾经应用自定义分类'
                this.arrList = this.forms.p4.values
                this.ruleForm.node = pn
                this.dialogVisible = true
            }
        },
        // 添加自定义分类
        subxit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var array = []
                    this.arrList.map(item=>{
                        if(item.type=='zdy'){
                            item.values.push({
                                key:'',
                                name:this.ruleForm.name,
                                val:0,
                            })
                        }
                    })
                    array = this.arrList
                    array.map((item,index)=>{
                        if(item.type=='zdy'){
                            item.values.map((items,indexs)=>{
                                items.key='zdy'+(indexs+1)
                            })
                        }
                    })
                    this.axios.StuCprojectsets1({
                        token:this.$store.state.token,
                        id:this.$store.state.cla_id,
                        node:this.ruleForm.node,
                        value:array
                    }).then(res=>{
                        if(res.data.code==0){
                            this.$refs[formName].resetFields();
                            this.dialogVisible = false
                            this.getapp()
                            this.arrList = []
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
        //删除自定义分类
        delclose(pn,index,indexs,items){
            if(pn=='p1'){
                this.forms.p1.values.map(item=>{
                    if(item.type=='zdy'){
                        const arrL = item.values.filter(el=>el.key != items.key)
                        item.values = arrL
                        item.values.map((itemss,indexss)=>{
                            itemss.key='zdy'+(indexss+1)
                        })
                    }
                })
                var arr = this.forms.p1.values
                this.axios.StuCprojectsets1({
                    token:this.$store.state.token,
                    id:this.$store.state.cla_id,
                    node:pn,
                    value:arr
                }).then(res=>{
                    if(res.data.code==0){
                        this.getapp()
                    }else{
                        this.getapp()
                        this.$message.error(res.data.message)
                    }
                }).catch(err=>{

                })
            }else if(pn=='p2'){
                this.forms.p2.values.map(item=>{
                    if(item.type=='zdy'){
                        const arrLs = item.values.filter(el=>el.key != items.key)
                        item.values = arrLs
                        item.values.map((itemss,indexss)=>{
                            itemss.key='zdy'+(indexss+1)
                        })
                    }
                })
                var arrs = this.forms.p2.values
                this.axios.StuCprojectsets1({
                    token:this.$store.state.token,
                    id:this.$store.state.cla_id,
                    node:pn,
                    value:arrs
                }).then(res=>{
                    if(res.data.code==0){
                        this.getapp()
                    }else{
                        this.getapp()
                        this.$message.error(res.data.message)
                    }
                }).catch(err=>{

                })
            }else if(pn=='p4'){
                this.forms.p4.values.map(item=>{
                    if(item.type=='zdy'){
                        const arrLss = item.values.filter(el=>el.key != items.key)
                        item.values = arrLss
                        item.values.map((itemss,indexss)=>{
                            itemss.key='zdy'+(indexss+1)
                        })
                    }
                })
                var arrss = this.forms.p4.values
                this.axios.StuCprojectsets1({
                    token:this.$store.state.token,
                    id:this.$store.state.cla_id,
                    node:pn,
                    value:arrss
                }).then(res=>{
                    if(res.data.code==0){
                        this.getapp()
                    }else{
                        this.getapp()
                        this.$message.error(res.data.message)
                    }
                }).catch(err=>{

                })
            }
        },
        // 选择分类数据
        itemsnum(pn,index,indexs,items){
            if(pn=='p1'){
                if(items.val==0){
                    this.forms.p1.values[index].values[indexs].val=1
                }else{
                    this.forms.p1.values[index].values[indexs].val=0
                }
                this.axios.StuCprojectsets1({
                    token:this.$store.state.token,
                    id:this.$store.state.cla_id,
                    node:pn,
                    value:this.forms.p1.values
                }).then(res=>{
                    if(res.data.code==0){
                        this.getapp()
                    }else{
                        this.getapp()
                        this.$message.error(res.data.message)
                    }
                }).catch(err=>{

                })
            }else if(pn=='p2'){
                if(items.val==0){
                    this.forms.p2.values[index].values[indexs].val=1
                }else{
                    this.forms.p2.values[index].values[indexs].val=0
                }
                this.axios.StuCprojectsets1({
                    token:this.$store.state.token,
                    id:this.$store.state.cla_id,
                    node:pn,
                    value:this.forms.p2.values
                }).then(res=>{
                    if(res.data.code==0){
                        this.getapp()
                    }else{
                        this.getapp()
                        this.$message.error(res.data.message)
                    }
                }).catch(err=>{

                })
            }else if(pn=='p4'){
                if(items.val==0){
                    this.forms.p4.values[index].values[indexs].val=1
                }else{
                    this.forms.p4.values[index].values[indexs].val=0
                }
                this.axios.StuCprojectsets1({
                    token:this.$store.state.token,
                    id:this.$store.state.cla_id,
                    node:pn,
                    value:this.forms.p4.values
                }).then(res=>{
                    if(res.data.code==0){
                        this.getapp()
                    }else{
                        this.getapp()
                        this.$message.error(res.data.message)
                    }
                }).catch(err=>{

                })
            }
        },
        // 提交
        submit(){
            this.axios.submits1({
                token:this.$store.state.token,
                id:this.$store.state.cla_id,
            }).then(res=>{
                if(res.data.code==0){
                    this.getapp()
                    this.$message.success('提交成功')
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        // 关闭弹窗
        closei(formName){
            this.dialogVisible = false
            this.$refs[formName].resetFields();
            this.arrList = []
            this.getapp()
        },
        handleClose(done){

        },
        // 下一步
        subNext(){
            this.$router.push({name:'Datacollection'})
        },
        // 输入框回车事件
        enterInput(e){
            if (e.which == 13 || e.charCode == 13 || e.keyCode == 13) {
                this.subxit('ruleForm');
            }
        }
    },
    watch:{
        searchVals(val){
            this.searchVal = val
        }
    },
    beforeDestroy () {
        let that = this;
        clearTimeout(that.timeout);
        clearTimeout(that.timeouts);
        clearTimeout(that.timeoutss)
        that.timeout = null;
        that.timeouts = null;
        that.timeoutss = null;
    },
    destroyed () {
        let that = this;
        clearTimeout(that.timeout);
        clearTimeout(that.timeouts);
        clearTimeout(that.timeoutss)
        that.timeout = null;
        that.timeouts = null;
        that.timeoutss = null;
    },
    mounted() {
        this.getapp()
        this.down()
    }
}
</script>
<style scoped>
@import './../../assets/css/student/Cognitivecomb.css';
</style>
<style>
.cogn_three_div .el-rate__item{
    vertical-align: top;
}
/* .show_t_div .el-rate__item:nth-child(4),.show_t_div .el-rate__item:nth-child(5){
    display: none;
} */
.search_div .el-input__inner{
    background: transparent;
    color: #fff;
}
.search_div .el-input__icon{
    color: #fff;
}
.search_div .el-input__inner:focus{
    border-color: #fff;
}
.cogn_ul_popup .el-dialog__body{
    padding: 30px 50px 10px;
}
.cogn_ul_popup .el-dialog__header{
    padding-top: 10px;
    text-align: center;
    border-bottom: 1px solid #DCDFE6;
    background: #4DA3D2;
}
.cogn_ul_popup .el-dialog__title{
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}
.cogn_ul_popup .el-form-item__content{
    margin-left: 0!important;
}
.cogn_ul_popup .el-form-item__label{
    font-weight: bold;
    color: #333;
    width: 100%;
    text-align: left;
}
.cogn_ul_popup .el-form-item--small.el-form-item{
    margin-bottom: 12px;
}
.cogn_ul_popup .el-dialog__footer{
    text-align: center;
}
.cogn_ul_popup .el-button--mini{
    width: 80px;
}
</style>